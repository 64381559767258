import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import envVariables from "src/lib/envVariables";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import UserCubit from "src/state/UserCubit/UserCubit";
import { tracker, useBloc } from "src/state/state";

type CustomerIoEventActionEvent = CustomEvent<{
  action?: string;
  actionName?: string;
  actionValue?: string;
  deliveryId?: string;
  message?: {
    dismiss?: () => void;
  };
  messageId?: string;
  name?: string;
}>;

type CustomerIoEventGeneric = CustomEvent<{
  deliveryId?: string;
  messageId?: string;
}>;

type CustomerIoEventNames = "action" | "dismissed" | "error" | "opened";

type CustomerIoEvent<T extends CustomerIoEventNames> = T extends "action"
  ? CustomerIoEventActionEvent
  : CustomerIoEventGeneric;

type CIOMockArrayItem = string | unknown;

interface CIOMock extends Array<CIOMockArrayItem> {
  identify: (data: { id: string }) => void;
  on: <TN extends CustomerIoEventNames>(
    event: `in-app:message-${TN}`,
    callback: (event: CustomerIoEvent<TN>) => void
  ) => void;
  _trackUrl?: string;
  [key: string]: CIOMockArrayItem | ((...args: CIOMockArrayItem[]) => void);
  track?: (eventName: string, data?: Record<string, unknown>) => void;
}

declare global {
  interface Window {
    _cio?: CIOMock;
  }
}

const CustomerIO: React.FC = () => {
  const [{ userData }] = useBloc(UserCubit);
  const navigate = useNavigate();

  const handleCIoAction = (event: CustomerIoEvent<"action">) => {
    try {
      event.detail.message?.dismiss?.();

      const { action } = event.detail;
      const query = new URLSearchParams(`?${action}`);

      const page = query.get("page");
      const asQueryString = query.toString();

      const targetUrl = page
        ? `/app/${page}?${asQueryString}`
        : `?${asQueryString}`;

      setTimeout(() => {
        navigate(targetUrl);

        tracker.track(`Customer.io action` as TrackEvent, {
          data: {
            messageId: event.detail.messageId,
            action: event.detail.action,
            targetUrl,
            actionName: event.detail.actionName
          }
        });
      }, 300);
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  const handleEvent =
    <TN extends CustomerIoEventNames>(type: CustomerIoEventNames) =>
    (event: CustomerIoEvent<TN>) => {
      switch (type) {
        case "opened":
        case "dismissed":
          tracker.track(`Customer.io message ${type}` as TrackEvent, {
            data: {
              messageId: event.detail.messageId
            }
          });
          break;
        case "action":
          handleCIoAction(event);
          break;
        case "error":
          reportErrorSentry(new Error("Customer.io message error"), {
            extra: event.detail
          });
          break;
      }
    };

  useEffect(() => {
    const isDev = !envVariables.PROD;
    const disabled =
      isDev && !featureFlags.getFlag(FeatureFlagName.customerIoInDevMode);

    // only load customer.io if we have a user email
    if (disabled || !userData?.email) {
      return;
    }

    if (!window._cio) {
      const _cio = [] as unknown as CIOMock;
      window._cio = _cio;

      let c: number;

      const a: (f: CIOMockArrayItem) => (...args: CIOMockArrayItem[]) => void =
        function (f) {
          return function (...args: CIOMockArrayItem[]) {
            _cio.push([f].concat(args));
          };
        };

      const b: string[] = [
        "load",
        "identify",
        "sidentify",
        "track",
        "page",
        "on",
        "off"
      ];

      for (c = 0; c < b.length; c++) {
        _cio[b[c]] = a(b[c]);
      }

      const t = document.createElement("script");
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      const s = document.getElementsByTagName("script")[0];
      t.async = true;
      t.id = "cio-tracker";
      t.setAttribute("data-site-id", envVariables.CUSTOMER_IO_SITE_ID);
      t.setAttribute("data-use-array-params", "true");
      t.setAttribute("data-use-in-app", "true");
      t.src = "https://assets.customer.io/assets/track.js";
      s.parentNode?.insertBefore(t, s);
    }

    window._cio.identify({
      id: userData.analyticsToken
    });

    window._cio.on("in-app:message-opened", handleEvent("opened"));
    window._cio.on("in-app:message-dismissed", handleEvent("dismissed"));
    window._cio.on("in-app:message-action", handleEvent("action"));
    window._cio.on("in-app:message-error", handleEvent("error"));
  }, [Boolean(userData?.email)]);

  return null;
};

export default CustomerIO;
